<template>
  <div>
       <Form label-position="right" :label-width="150" style="margin:30px 100px 0 0">
           <FormItem >
                <span slot="label" class="validate">区域上级:</span>
                <Cascader
                    v-model="moveData.transferOrgid"
                    :data="chinaList"
                    :load-data="loadChinaCommunity"
                    change-on-select
                    @on-change="changeCasder"
                ></Cascader>
            </FormItem>
           <FormItem>
                <span slot="label" class="validate" >区域名称:</span>
                <Input v-model="moveData.orgName"  placeholder="请输入迁移后区域名称"  :maxlength="30"></Input>
            </FormItem>
            <FormItem>
                <span slot="label" class="validate">区域编码:</span>
                <Input v-model="moveData.orgCode " placeholder="请输入迁移后区域编码"  :maxlength="30"></Input>
            </FormItem>

       </Form>
  </div>
</template>


<script>
  export default {
    props:{
        moveData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
    },
    created(){
        this.getAsia('ASIA','chinaList')
        // this.changeCasder()
        
    },
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
      //这里存放数据
      return {
        chinaList:[],//全国地址的参数
        showStatus:false
      }
    },
    methods: {
        getasia(){
            this.getAsia('ASIA','chinaList')
        },
        // 获取全国范围的
        getAsia(code,list){
             this.$get("/orgzz/pc/organization/selectOrganizationByOrgCode", { 
                orgCode: code,
                }).then(res => {
                    if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                        value: item.orgId,
                        label: item.orgName,
                        orgLevel: item.orgLevel,
                        loading: false,
                        children: [],
                        orgCode:item.orgCode
                        };
                    });
                    }
                });
        },
        // 异步加载中国的接口
        loadChinaCommunity(item,callback){
            item.loading = true;
            let Code = item.orgCode;
            console.log(123)
            this.$get("/orgzz/pc/organization/selectOrganizationByOrgCode", {
                orgCode: Code,
            }).then(res => {
                if (res.code == 200) {
                    if(res.dataList.length == 0){
                        item.loading = false;
                        // delete item.children
                        // delete item.loading
                        delete(item['children'])
                        delete(item['loading'])
                        return
                    }
                    
                    if (item.orgLevel != '3') {
                        item.children = res.dataList.map(item => {
                        return {
                            value: item.orgId,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                            orgCode:item.orgCode
                        };
                        });
                    } 
                    else {
                        item.children = res.dataList.map(item => {
                        return {
                            value: item.orgId,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                        };
                        });
                    }
                        item.loading = false;
                        callback();
                        console.log(this.chinaList)
                    }
            });
        },
        changeCasder(val){
            console.log(val)
            if(val.length == 4){
                this.showStatus = true
            }else{
                this.showStatus = false
            }
        }
     
    },
    watch:{
        // formData(val,newVal){
        //     console.log(val)
        //     this.changeCasder(this.chinaList[0])
        //     if(val == false){
        //         console.log(123)
        //     }
        // },
    },
    components:{
    }
  }
</script>
<style lang='less' scoped>
   /deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
  }
  /deep/textarea{
      resize:none
  }
</style>