<template>
  <LiefengContent>
    <template #title>行政区划
    </template>
    <template #toolsbarRight>
        <Button
            icon="ios-add"
            type="primary"
            style="margin-right: 0"
            @click="add()"
            >新建</Button
        >
        
      
    </template>
    <template #contentArea>
        <div class="department-table">
            <Table :loading='loading'  :load-data="handleLoadData" :columns="talbeColumns" :data="tableData" row-key="orgCode" height="700" border stripe></Table>
        </div>
      <LiefengModal 
        :title="isAdd?'新增':'修改'"
        :value="detailStatus"
        @input="changeDetailStatus"
        :width='800'
        >
        <template v-slot:contentarea>
           <AddForm ref='addForm' :formData='formData' :isAdd='isAdd' :isChild='isChild' @getStatus='getStatus'></AddForm>
        </template>
        <template v-slot:toolsbar>
            <Button type="primary" style="marginRight:10px" @click="changeDetailStatus(false)">取消</Button>
             <Button type="primary" style="marginRight:10px" :loading='saveLoading' @click="save">保存</Button>
        </template>
      </LiefengModal>
      <LiefengModal 
        title="迁移"
        :value="moveStatus"
        @input="changeMoveStatus"
        :width='800'
        >
        <template v-slot:contentarea>
           <moveForm ref='moveForm' :moveData='moveData' ></moveForm>
        </template>
        <template v-slot:toolsbar>
            <Button type="primary" style="marginRight:10px" @click="changeMoveStatus(false)">取消</Button>
             <Button type="primary" style="marginRight:10px" :loading='moveLoading' @click="saveMove">保存</Button>
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import AddForm from "./children/addform.vue"
import moveForm from "./children/moveform.vue"
export default {
  data() {
    return {
      keyWord:'',
      saveLoading:false,
      moveStatus:false,
      moveLoading:false,

      totalNum:0,
      readNum:0,
      unreadNum:0,

      tableData: [],
      talbeColumns: [
        {
          title: "ID",
          key: "orgId",
          width: 80,
          align: 'center',
        },
        {
          title: "名称",
          key: "orgName",
          minWidth: 250,
          tree: true,
        },
        {
          title: "编码",
          key: "orgCode",
          minWidth: 140,
          align: "center",
        },
        
        {
          title: "编码路径",
          key: "orgPath",
          minWidth: 300,
          align: "center",
        },
        {
          title: "社区编码",
          key: "mappingCode",
          minWidth: 140,
          align: "center",
        },
        {
          title: "备注",
          key: "orgDesc",
          minWidth: 200,
          align: "center",
        },
        {
          title: "创建时间",
          minWidth: 200,
          align: "center",
          render:(h,params) => {
              return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                  new Date(params.row.gmtCreate),
                  "yyyy-MM-dd hh:mm:ss"
              ):'')
          }
        },
        {
          title: "排序",
          key: "seq",
          width: 80,
          align: "center",
        },
        {
          fixed:'right',
          title: "操作",
          minWidth: 300,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      console.log(params.row)
                      if(params.row.orgLevel == 5){
                        this.fillStatus = true
                      }
                      this.onDetail(params.row.orgId);
                    },
                  },
                },
                "编辑"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    display:params.row.orgLevel == '5'?'none':'inline-block',
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      if(params.row.orgLevel == 4){
                        this.fillStatus = true
                      }
                      
                      this.add(params.row.orgId,params.row.orgLevel);
                    },
                  },
                },
                "添加下级"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      this.move(params.row);
                    },
                  },
                },
                "迁移"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      this.delete(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      detailStatus:false,
      isAdd:true,
      isChild:false,
      formData:{},
      moveData:{},
      fillStatus:false,
    };
  },


  methods: {
    //分页
    hadlePageSize(){

    },
    add(id,orgLevel){
      // console.log(id)
        this.isChild = false
        // console.log(this.isChild)
        this.isAdd = true
        this.changeDetailStatus(true)
        this.formData = {
          parentId:[],
          orgName:'',
          orgCode:'',
          mappingCode:'',
          seq:'',
          status:'1',
          orgDesc:'',
        }
        if(id){
          this.isChild = true
          this.formData.parentId.push(id) 
          if(orgLevel == 4){
            this.formData.orgLevel=5
          }
        }
    },
    onDetail(orgId){
        this.isAdd = false
        this.changeDetailStatus(true)
        this.$get('/orgzz/pc/organization/selectByOrgId',{
          orgId:orgId
        }).then( res => {
          if(res.code == 200){
            this.formData = res.data
          }
        })
    },
    delete(data){
         this.$Modal.confirm({
            title: "温馨提示",
            loading:true,
            content:`是否删除 <span style='color:red'>${data.orgName}</span> 该行政区域`,
            onOk:() => {
              this.$get('/orgzz/pc/organization/delete',{
                  orgId:data.orgId,
              }).then(res => {
                if(res.code == 200){
                  this.$Modal.remove();
                  this.$Message.success({
                    content:'删除成功',
                    background:true
                  })
                  this.getList()
                }else{
                  this.$Modal.remove();
                  this.$Message.error({
                    content:res.desc,
                    background:true
                  })
                }
              })            
            }
         })
    },
    changeDetailStatus(status){
        if(!status){
          this.$refs.addForm.changeShowStatus()
        }
        this.detailStatus = status
    },
    changeMoveStatus(status){
      this.moveStatus = status
    },
    //获取下级数据
    handleLoadData(item,callback){
        // console.log(item,callback)
        item._loading = true
        item.children = []
        this.$get('/orgzz/pc/organization/selectOrganizationByOrgCode',{
          orgCode:item.orgCode
        }).then(res=>{
            let arr = []
            // item._loading = false
            if(res.code == 200 && res.dataList.length != 0 ){
              res.dataList.map(items=>{
                items._loading = false
                items.children = []
              })
              arr = res.dataList
              callback(arr)
            }else{
              item._loading = false
              delete item.children
              delete item._loading
            }
            // console.log(this.tableData)
        })
    },
    getList(){
            this.loading = true
            this.$get('/orgzz/pc/organization/selectOrganizationByOrgCode',{
                orgCode: 'CN'
                // page:this.page,
                // pageSize:this.pageSize
            }).then(res=>{
                if(res.code == 200){
                    this.loading = false
                    if(res.dataList.length != 0){
                      res.dataList.map(item=>{
                        item._loading = false
                        item.children = []
                      })
                    }
                    this.tableData = res.dataList
                    // this.total = res.maxCount
                    // this.currentPage = res.currentPage
                    // console.log(this.tableData)
                }else{
                     this.loading=false
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
    },
    getStatus(val){
      console.log(val)
      this.fillStatus = val
    },
    save(){  
      // console.log()
      // console.log(this.fillStatus)
      let data = {
          parentId : this.formData.parentId[this.formData.parentId.length-1],
          orgName : this.formData.orgName,
          orgCode : this.formData.orgCode,
          orgId:this.formData.orgId,
          mappingCode : this.formData.mappingCode,
          seq:this.formData.seq,
          status:this.formData.status,
          orgDesc:this.formData.orgDesc,
          oemCode:parent.vue.oemInfo.oemCode,
          dimensionId:'1',
      }
      console.log(data)
      if(!data.orgName){
        this.$Message.warning({
          content:'请填写区域名称',
          background:true
        })
        return
      }
      if(!data.orgCode){
        this.$Message.warning({
          content:'请填写区域编码',
          background:true
        })
        return
      }
      console.log(this.fillStatus)
      console.log(data.mappingCode)
      if( this.fillStatus &&  !data.mappingCode){
        this.$Message.warning({
          content:'请填写社区(村)编码',
          background:true
        })
        return
      }
      
      let testCode = /^[A-Za-z0-9]{4,40}$/
      if(!testCode.test(data.orgCode)){
        this.$Message.warning({
          content:'区域编码-请输入数字或字母',
          background:true
        })
        return
      }
      if(data.mappingCode && !testCode.test(data.mappingCode)){
        this.$Message.warning({
          content:'社区(村)编码-请输入数字或字母',
          background:true
        })
        return
      }
      
      if(this.isAdd){
        if(!data.parentId){
          this.$Message.warning({
            content:"请选择上级区域",
            background:true
          })
          return
        }
        this.saveLoading = true
        delete(data['orgId'])
        this.$post('/orgzz/pc/organization/autoBuildOrgDetailNoToken',{
          ...data
        },{"Content-Type": "application/json"}).then( res => {
          if(res.code == 200){
            this.$Message.success({
              content:'新增成功',
              background:true
            })
            this.changeDetailStatus(false)
            this.saveLoading = false
            this.$refs.addForm.getasia()
            this.getList()
          }else{
            // this.changeDetailStatus(false)
            this.saveLoading = false
            this.$Message.error({
              content:res.desc,
              background:true
            })
          }
        })
      }else{
        delete(data['parentId'])
        this.saveLoading = true
        this.$post('/orgzz/pc/organization/updateOrganization',{
          ...data
        },{"Content-Type": "application/json"}).then( res => {
            if(res.code == 200){
              this.changeDetailStatus(false)
              this.$Message.success({
                content:'修改成功',
                background:true
              })
              this.saveLoading = false
              this.getList()
            }else{
              // this.changeDetailStatus(false)
              this.saveLoading = false
              this.$Message.error({
                content:res.desc,
                background:true
              })
            }
        })
      }
      
      // console.log(this.formData)
    },
    
    move(data){
        this.changeMoveStatus(true)
        this.moveData = {
          orgCode:data.orgCode,
          orgId:data.orgId,
          orgName:data.orgName,
          transferOrgid:''
        }
    },
    saveMove(){
      // console.log(this.moveData)
      if(!this.moveData.transferOrgid){
        this.$Message.warning({
          content:'请选择迁移上级区域',
          background:true
        })
        return
      }
      if(!this.moveData.orgName){
        this.$Message.warning({
          content:'请填写迁移后区域名称',
          background:true
        })
        return
      }
      if(!this.moveData.orgCode){
        this.$Message.warning({
          content:'请填写迁移后区域编码',
          background:true
        })
        return
      }
      let testCode = /^[0-9a-zA-Z]*$/g
      if(!testCode.test(this.moveData.orgCode)){
        this.$Message.warning({
          content:'迁移后区域编码-请输入数字或字母',
          background:true
        })
        return
      }
      this.moveLoading = true
      this.moveData.transferOrgid = this.moveData.transferOrgid[this.moveData.transferOrgid.length - 1]
      this.$post('/orgzz/pc/organization/transferOrg',{
        ...this.moveData
      },{"Content-Type": "application/json"}).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content:'迁移成功',
            background:true
          })
          this.changeMoveStatus(false)
            this.moveLoading = false
            this.$refs.moveForm.getasia()
            this.getList()
        }else{
          // this.changeMoveStatus(false)
          this.moveLoading = false
          this.$Message.error({
            content:res.desc,
            background:true
          })
        }
        
      })
    }
  },
  created() {
      this.getList()
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddForm,
    moveForm
  },
};
</script>
    
<style scoped lang='less'>
.readnum{
        display: inline;
        margin-left:20px;
        font-size:14px;
        font-weight:700;
    li{
        display: inline;
        margin-left: 15px;
    }
}
.readNum{
        margin-top: 5px;
        font-size:14px;
        font-weight:700;
        height: 20px;
    li{
        display: inline;
        margin-left: 15px;
    }
}
/deep/ .ivu-table-cell{
  display: flex !important;
  justify-content: left;
  .ivu-icon{
    font-size: 20px;
    color: white;
    background: rgb(128, 128, 128);
    border-radius: 50%;
    margin-right: 5px;
    font-weight: bold;
  }
}

</style>